import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { addDays, format } from 'date-fns'
import {
  ChevronUpMinor, ChevronDownMinor, ChevronRightMinor, ClockMajor
} from '@shopify/polaris-icons';

import { Badge, Button, Icon, IndexTable, useIndexResourceState, Toast, Frame, Text, Tooltip, LegacyStack } from "@shopify/polaris";
import { ChevronUpIcon, ChevronDownIcon, ChevronRightIcon, ClockIcon, AlertDiamondIcon } from "@shopify/polaris-icons";
import styled from 'styled-components';
import {navigate} from "gatsby";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { updateRulesPriority } from '../../../helpers/updateRule';
import FirebaseContext from '../../../providers/firebase';
import formatCurrency from '../../../helpers/formatCurrency';
import ClipboardMinor from '../../../images/ClipboardMinor.png';
import PopoverAction from './common/popoverAction/popoverAction';
import offerActions from './utils/createOfferActions';
import CustomIndexFilters from './components/customIndexFilters/customIndexFilters';
import {OFFER_FILTERS, OFFER_TYPES_FILTERS} from '../../../constants/constants';
import AutopilotProductIcon from '../../../images/AutopilotProductIcon.png';
import AiProductIcon from '../../../images/AiProductIcon.png';
import './list.css';
import compatiblePPOffer from '../../../helpers/compatiblePPOffer'
const ListMainWrapper = styled.div`
  .Polaris-Frame {
    max-height: 0;
    min-height: 0;
  }
  
  .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
    position: unset;
  }
  
  .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--second {
    filter: unset;
  }
  
  table thead th {
    background-color: #FAFBFB !important;
    color: #6D7175;
  }
`

const ActionsWrapper = styled.div`
    display: flex;
    gap: 2rem;
    .duplicate-action {
        .Polaris-Button {
        border: none;
        box-shadow: none;
      }
    }

    .action-list {
      padding: 5px;
      overflow: hidden;
    }
    
`
const ChevronWrapper = styled.div`
  .Polaris-Icon {
    border-bottom: 1px dotted #000;
  }
`
const OfferIdContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .Polaris-Button {
    margin-left: 1px;
  }
  .Polaris-Button:hover {
    background-color: #e9e9e9;
  }
`
const StatusScheduleWrapper = styled.div`
  position: relative;
`
const ClockIconWrapper = styled.div`
  position: absolute;
  left: -30px;
`

function List(props) {
  const { rules, locale, currency, analytics, chartDateRange} = props;
  const { firebase, shop, token, host } = useContext(FirebaseContext);
  const [accountStatus, setAccountStatus] = useState(null);
  const [rulePopoverActive, setRulePopoverActive] = useState(null);
  const [productAnalytics, setProductAnalytics] = useState(null);
  const [toastActive, setToastActive] = useState({active: false, message: ""});

  const [selectedFilters, setSelectedFilters] = useState(OFFER_FILTERS[0]);
  const [selectedOffersFilters, setSelectedOffersFilters] = useState([]);
  const [sortOption, setSortOption] = useState('none');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if(rules && Array.isArray(rules) && window && window.$crisp){
      try{
        window.$crisp.push(["set", "session:data", [[
          ["offers_created", rules.length],
          ["offers_enabled", rules.filter(r => r.enabled).length],
        ]]]);
      }catch(e){
        console.log(e)
      }
    }
}, [rules]); 



  const handleTextFieldChange = useCallback((value) => setSearchValue(value),[setSearchValue]);

  const togglePopoverActive = useCallback((id) => setRulePopoverActive(id), [setRulePopoverActive]);

  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(null),
    [],
  );

  /** Polaris shopify hook for working with data from an index table */
  const {selectedResources, allResourcesSelected, handleSelectionChange} = useIndexResourceState(rules);

  const appliedFilters = [];
  if (!isEmpty(accountStatus)) {
    const key = 'accountStatus';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }

  function moveItem(from, to) {
    // remove `from` item and store it
    const data = rules
    var f = data.splice(from, 1)[0];
    // insert stored item into position `to`
    data.splice(to, 0, f);
    updateRulesPriority(shop,firebase,data)
  }
  const moveUp = (e, index) => {
    e.stopPropagation();
    moveItem(index, index-1)
  }
  const moveDown = (e, index) => {
    e.stopPropagation();
    moveItem(index, index+1)
  }

  const getOfferType = (rule) => {
    if (rule.offerType) {
      let offerTypeTitle = '';
      switch (rule.offerType) {
        case 'Checkout':
          return rule?.checkoutType && "extension" === rule?.checkoutType
            ? <Badge tone="info">Checkout Extension</Badge>
            : <Badge tone="info">Checkout.liquid</Badge>;
        case 'Cart':
          offerTypeTitle = rule?.cartType && "page" === rule?.cartType
            ? 'Cart Page'
            : rule?.cartType && "drawer" === rule?.cartType
              ? 'Cart Drawer'
              : 'Cart Popup'
          return <Badge tone="warning">{offerTypeTitle}</Badge>;
        case 'Product':
          return <Badge tone="new">{rule.offerType}</Badge>;
        default:
          return <span className="Polaris-Badge Polaris-Badge--statusSuccess" style={{ background:'#A6A4F2'}}>{rule.offerType}</span>;
      }
    } else {
      return <Badge tone="info">Checkout</Badge>;
    }
  }

  const copyIdToClipboard = (e, id) => {
    e.stopPropagation();
    navigator.clipboard.writeText(id)
      .then(() => setToastActive({active: true, message: 'ID copied'}))
      .catch((err) => console.log('Error in copy offer id: ', err));
  }

  const toggleActive = useCallback(() => setToastActive((prevState) => ({...prevState, active: !prevState.active})), []);

  const toastMarkup = toastActive.active ? (
    <Toast content={toastActive.message} onDismiss={toggleActive} duration={1500} />
  ) : null;

  const navigateToOffer = async (id, offerType, cartType, checkoutType, rule) => {
    try {
      if("Post Purchase" === offerType){
        await navigate(
          `/app/offers/postPurchaseOfferItem`,
          {
            state: {
              chartDateRange: chartDateRange,
              rule: compatiblePPOffer(rule),
              rules: rules,
            },
            replace: true,
          }
        )
      } else if ("Product" === offerType) {
        await navigate(
          `/app/offers/productOfferItem`,
          {
            state: {
              chartDateRange: chartDateRange,
              rule: rule,
              rules: rules,
            },
            replace: true,
          }
        )
      } else {
        if("Cart" === offerType){
          if("drawer" === cartType){
            navigate(
              `/app/offers/cartDrawerOfferItem`,
              {
                state: {
                  chartDateRange: chartDateRange,
                  rule: rule,
                  rules: rules,
                },
                // replace: true,
              }
            )
          }else{
            await navigate(
              `/app/offers/cartOfferItem`,
              {
                state: {
                  chartDateRange: chartDateRange,
                  rule: rule,
                  rules: rules,
                },
                // replace: true,
              }
            )
          }
        }else{
          if("extension" === checkoutType){
            await navigate(
              `/app/offers/checkoutExtensionOfferItem`,
              {
                state: {
                  chartDateRange: chartDateRange,
                  rule: rule,
                  rules: rules,
                  versionAnalytics: analytics && analytics[id] && analytics[id]['version'] || null,
                },
                // replace: true,
              }
            )
          }else{
            await navigate(
              `/app/offers/item`,
              {
                state: {
                  chartDateRange: chartDateRange,
                  rule: rule,
                  rules: rules,
                  versionAnalytics: analytics && analytics[id] && analytics[id]['version'] || null,
                },
                replace: true,
              }
            )
          }
        }
      }
    } catch (err) {
      console.log('Error in redirection: ', err)
    }
  }

  const normalizeShopifyID = (id) => {
    return String(id)
      .replace('gid://shopify/ProductVariant/', '')
      .replace('gid://shopify/Product/', '');
  };

  const truncate = (str, n) => {
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
  };
  function numberWithCommas(x) {
    if(x){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
      return 0;
    }
  }
  const renderRows = () => {
    let itemImage = 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b';

    if (rules) {
      let filterFn;
      let sortFn;

      switch (selectedFilters) {
        case 'All':
          filterFn = (item) => !item?.archived;
          break;
        case 'Active':
          filterFn = (item) => item?.enabled && item;
          break;
        case 'Draft':
          filterFn = (item) => (!item?.enabled && !item?.archived) && item;
          break;
        case 'Archived':
          filterFn = (item) => (!item?.enabled && item?.archived) && item;
          break;
        default:
          filterFn = (item) => item;
          break;
      }

      switch (sortOption) {
        case 'name':
          sortFn = ({name: nameA}, {name: nameB}) => {
            if (sortDirection === 'asc') {
              return nameA.localeCompare(nameB);
            } else {
              return nameB.localeCompare(nameA);
            }
          };
          break;
        case 'impressions':
          sortFn = ({analytics: a}, {analytics: b}) => {
            if (sortDirection === 'asc') {
              return (a?.impressions - b?.impressions);
            } else {
              return (b?.impressions - a?.impressions);
            }
          };
          break;
        case 'revenue':
          sortFn = ({analytics: a}, {analytics: b}) => {
            if (sortDirection === 'asc') {
              return (a?.revenue - b?.revenue);
            } else {
              return (b?.revenue - a?.revenue);
            }
          };
          break;
        case 'conversion':
          sortFn = ({analytics: a}, {analytics: b}) => {
            const convA = a?.conversion.replace('%', '');
            const convB = b?.conversion.replace('%', '');

            if (sortDirection === 'asc') {
              return (Number(convA) - Number(convB));
            } else {
              return (Number(convB) - Number(convA));
            }
          };
          break;
        default:
          sortFn = (itemA, itemB) => itemA === itemB;
          break;
      }

      let preparedRules = rules.filter(filterFn).map((item) => ({
        ...item,
        analytics: {
          ...analytics[item.id],
          conversion: analytics[item.id]?.conversion || "0.00%",
          impressions: analytics[item.id]?.impressions || 0,
          revenue: analytics[item.id]?.revenue || 0,
        },
      }));

      if (sortOption !== 'none') {
        preparedRules = preparedRules.sort(sortFn);
      }

      if (searchValue !== '') {
        preparedRules = preparedRules.filter(({name}) => name.includes(searchValue));
      }

      if (Array.isArray(selectedOffersFilters) && selectedOffersFilters.length > 0) {
        preparedRules = preparedRules.filter((rule) => {
          return selectedOffersFilters.some((offerType) => {
            if (offerType === OFFER_TYPES_FILTERS[0]) {
              return rule?.offerType === 'Checkout' && rule?.checkoutType !== 'extension';
            }
            if (offerType === OFFER_TYPES_FILTERS[1]) {
              return rule?.offerType === 'Checkout' && rule?.checkoutType === 'extension';
            }
            if (offerType === OFFER_TYPES_FILTERS[2]) {
              return rule?.offerType === 'Post Purchase';
            }
            if (offerType === OFFER_TYPES_FILTERS[3]) {
              return rule?.offerType === 'Cart' && rule?.cartType === 'drawer';
            }
            if (offerType === OFFER_TYPES_FILTERS[4]) {
              return rule?.offerType === 'Cart' && rule?.cartType === 'page';
            }
            if (offerType === OFFER_TYPES_FILTERS[5]) {
              return rule?.offerType === 'Cart' && rule?.cartType !== 'drawer' && rule?.cartType !== 'page';
            }
          });
        });
      }

      return preparedRules.map(
        (rule, index) => {
          const { id, name, offerType, checkoutType, cartType, product, variant, variants, enabled, layout, shopBrain = null, variantsTriggers, offerStarting, offerEnding } = rule;
          let date = new Date();
          let startingDate, endingDate, scheduleStatus;
          if (offerStarting && offerEnding ) {
            if (offerStarting.seconds && offerEnding.seconds) {
              startingDate = new Date(offerStarting.seconds * 1000);
              endingDate = new Date(offerEnding.seconds * 1000);
            } else {
              startingDate = new Date(offerStarting);
              endingDate = new Date(offerEnding);
            }
            if (date < startingDate) {
              scheduleStatus = "Scheduled"
            } else if (date > endingDate) {
              scheduleStatus = "Disabled"
            } else {
              scheduleStatus = "Enabled"
            }
          }
          /** Rule's index in the list of all rules before filtering */
          const mainIndex = rules.findIndex((item) => item.id === id);

          if (shopBrain) {
            itemImage = (variantsTriggers && variantsTriggers.type && variantsTriggers.type === 'autopilot') ? AutopilotProductIcon : AiProductIcon;
          } else {
            if (Array.isArray(product) && product.length > 1) {
              if ("carousel" === layout) {
                itemImage = (product.length > 0 && product[0].product && product[0].product.image)
                  ? product[0].product.image
                  : (Array.isArray(product[0].product?.images) && product[0].product?.images.length > 0) && product[0].product?.images[0]?.originalSrc;
              } else {
                if ("stacked" === layout || "responsive" === layout || "row" === layout) {
                  itemImage = (product.length > 0 && product[0].product && product[0].product.image) ? product[0].product.image : (product[0].product?.images && Array.isArray(product[0].product?.images) && product[0].product?.images.length > 0) ? product[0].product?.images[0]?.originalSrc : null;
                } else {
                  if ("one" === layout) {
                    itemImage = product.length > 0 && product[0].product && product[0].product.image
                  } else
                  if (product[0]?.featuredImage) {
                    itemImage = product.length > 0 && product[0]?.featuredImage && product[0].featuredImage
                  }
                }
              }
              if ("Post Purchase" === offerType && Array.isArray(product[0].products) && product[0].products.length > 0 ) {
                itemImage = product[0]?.products[0]?.featuredImage
              }
            } else {
              if (Array.isArray(product)) {
                if (product.length > 0) {
                  itemImage = (product[0].product && product[0].product.image) ? product[0].product.image : (Array.isArray(product[0].product?.images)?product[0].product?.images[0]?.originalSrc:null);
                  if ("Post Purchase" === offerType) {
                    itemImage = product[0]?.featuredImage
                    if (Array.isArray(product[0].products) && product[0].products.length > 0 ) {
                      itemImage = product[0]?.products[0]?.featuredImage
                    }
                  }
                }
              } else {
                itemImage = (variants && variants[0]?.image) || ((product && product.image) || (product && product.images && product.images[0]?.originalSrc))
              }
            }
          }

          !itemImage && (itemImage = 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b');

          const renderImage = Array.isArray(product) && product.length > 1 ?
            <div style={{position:'relative'}}>
              <img
                style={{
                  width: 60,
                  height: 60,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                src={itemImage}
                alt={'ProductImage'}/>
              <div style={{position:'absolute',right:'5px',bottom:'10px'}}>
                <Badge>{product.length}</Badge>
              </div>
            </div>
            :
            <img
              style={{
                width: 60,
                height: 60,
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={itemImage}
              alt={'ProductImage'}/>

          const renderPriority =
            <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{paddingRight:'10px'}}>
                <h2>
                  <Text  variant="bodyMd" as="span" fontWeight="semibold">#{index+1}</Text>
                </h2>
              </div>
              <div style={{display: 'flex', flexDirection: 'row',  justifyContent: 'center'}}>
                <div style={{border: '1px solid #C9CCCF', borderRadius: '5px 0 0 5px', borderRight: 'none', padding: '7px 1px'}} onClick={(e) => { e.stopPropagation(); if(index > 0) {moveUp(e,  mainIndex)}}}>
                  <Icon
                    source={ChevronUpIcon}
                    tone={index > 0 ? "base": "subdued"} />
                </div>
                <div style={{border: '1px solid #C9CCCF', borderRadius: '0 5px 5px 0', padding: '7px 1px'}} onClick={(e) => { e.stopPropagation(); if(index < preparedRules.length-1) { moveDown(e,  mainIndex) }}}>
                  <Icon
                    source={ChevronDownIcon}
                    tone={index < preparedRules.length-1 ? "base": "subdued" } />
                </div>
              </div>
            </div>;

          const renderName =
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: '160px',
                minWidth: '120px',
                minHeight: '81px',
                padding: '1.6rem',
              }}
            >
              <div /*style={{display: 'flex', marginBottom: 4}}*/ className='OfferTitle'>
                <h3>
                  <Text  variant="bodyMd" as="span" fontWeight="semibold">{name}</Text>
                </h3>
              </div>
              {(product && (!shopBrain))?
                Array.isArray(product)?
                 (product.length > 0 && product[0].products ?
                  <div className='OfferProducts'>{product.length} {product.length > 1 ? "pages":"page"}, {product.reduce((a, p) => a + p.products.length, 0)} {product.reduce((a, p) => a + p.products.length, 0) > 1 ? "products":"product"}</div>
                  :
                  <div className='OfferProducts'>{product.map(p => p.product ? p.product.title: "").join("/")}</div>
                 )
                  :
                  variants ?
                    <div className='OfferProducts'>{product.title} - {variants.map(variant => variant.title).join("/")}</div>
                    :
                    <div className='OfferProducts'>{product.title} - {variant.title}</div>
                :
                false
              }
            </div>;

          const renderImpressions =
            <div style={{display: 'flex'}}>
              { offerType === "Checkout" && Array.isArray(product) && product.length > 1 && analytics && analytics[id]
                ?
                  <ChevronWrapper onClick={(e) => {
                    e.stopPropagation();
                    if (id === productAnalytics) {
                      setProductAnalytics(null)
                    } else {
                      setProductAnalytics(id)
                    }
                  }}>
                    <Icon
                      source={id === productAnalytics ? ChevronDownIcon : ChevronRightIcon}
                      tone="base" />
                  </ChevronWrapper>
                :
                  <div></div>
              }
              {analytics && analytics[id] && numberWithCommas(analytics[id].impressions) || 0}
            </div>;
          
          const removedIds = analytics && analytics[id] && analytics[id]['product'] && Array.isArray(product) && Object.keys(analytics[id]['product']).filter(k=>!product.find(p => p.product && normalizeShopifyID(p.product.id) === k));
          
          const missingProduct = !shopBrain && Array.isArray(product) && (product.length === 0 ||
          product.find( p => 
            {
              if("Post Purchase" === offerType){
                return (Array.isArray(p?.products) && p.products.find(p1 => (p1?.product && typeof p1?.product === 'object' && Object.keys(p1?.product).length === 0) || (p1?.variants && Array.isArray(p1?.variants) && p1.variants.length === 0)) !== undefined)
              }else{
                return (p?.product && typeof p?.product === 'object' && Object.keys(p?.product).length === 0) || (p?.variants && Array.isArray(p?.variants) && p.variants.length === 0)
              }
            }
          ) !== undefined )
          return (
            <IndexTable.Row
              id={`${id}`}
              key={`${index}`}
              selected={selectedResources.includes(`${id}`)}
              position={index}
              tone={!enabled && 'subdued'}
              onNavigation={ async (id) => {
                await navigateToOffer(id, offerType, cartType, checkoutType, rule)
              }}
            >
              <IndexTable.Cell/>
              <IndexTable.Cell>{renderImage}</IndexTable.Cell>
              <IndexTable.Cell>{renderPriority}</IndexTable.Cell>
              <a style={{textDecoration: 'none', color: 'black'}} href={'#'} data-primary-link >
                {renderName}
                {
                  id === productAnalytics && analytics[id] &&
                    <div style={{padding: '1.6rem'}}>
                      <div style={{height:'2rem'}}></div>
                      {
                        product.map(p => {
                          return <div className='ProductTitle'>{p.product && p.product.title && truncate(p.product.title, 33)}</div>
                        })
                      }
                      {
                        removedIds && removedIds.map(i => {
                          return <div style={{color:'grey'}} className='ProductTitle'>{(analytics[id]['product'] && analytics[id]['product'][i] && analytics[id]['product'][i].name && truncate(analytics[id]['product'][i].name, 33)) || i}</div>
                        })
                      }
                    </div>
                }
              </a>
              <IndexTable.Cell>
                { 
                  (rule.offerStarting && rule?.offerEnding && rule?.offerStarting !== "" && rule?.offerEnding !== "") ?
                  (rule.offerStarting?.seconds &&  rule.offerEnding?.seconds ?
                    <Tooltip content={`Scheduled to start on ${format(new Date(rule.offerStarting.seconds * 1000), "EEEE MMM d, yyyy")} and end on ${format(new Date(rule.offerEnding.seconds * 1000), "EEEE MMM d, yyyy")}` }>
                      <StatusScheduleWrapper>
                        <ClockIconWrapper>
                          <Icon
                            source={ClockIcon}
                            color="base" />
                        </ClockIconWrapper>
                        <Badge tone={scheduleStatus === "Enabled" && "success"}>{scheduleStatus}</Badge>
                      </StatusScheduleWrapper>
                    </Tooltip>
                    :
                    <Tooltip content={`Scheduled to start on ${format(new Date(rule.offerStarting), "EEEE MMM d, yyyy")} and end on ${format(new Date(rule.offerEnding), "EEEE MMM d, yyyy")}` }>
                      <StatusScheduleWrapper>
                        <ClockIconWrapper>
                          <Icon
                            source={ClockIcon}
                            color="base" />
                        </ClockIconWrapper>
                        <Badge tone={scheduleStatus === "Enabled" && "success"}>{scheduleStatus}</Badge>
                      </StatusScheduleWrapper>
                    </Tooltip>
                  )
                  :
                  (missingProduct ? 
                  <LegacyStack spacing="extraTight">
                    {
                    enabled ?
                      <Badge tone="success">Enabled</Badge>
                      :
                      rule?.archived ?
                        <Badge tone="warning">Archived</Badge>
                        :
                        <Badge>Disabled</Badge>
                    }
                    {
                      missingProduct && <Tooltip content="Upsell product or variant was deleted from your store. The product won’t show untill you select a new product." dismissOnMouseOut><Icon source={AlertDiamondIcon} tone="critical"/></Tooltip>
                    }
                 </LegacyStack>
                  :
                  (enabled ?
                  <Badge tone="success">Enabled</Badge>
                  :
                  rule?.archived ?
                    <Badge tone="warning">Archived</Badge>
                    :
                    <Badge>Disabled</Badge>))
              }
              </IndexTable.Cell>
              <IndexTable.Cell>{getOfferType(preparedRules[index])}</IndexTable.Cell>
              <IndexTable.Cell>
                <div>
                  {renderImpressions}

                  {
                    id === productAnalytics && analytics[id] &&
                      <div className='MultiProductAnalytics'>
                        <div className='MultiProductAnalytics__column-title'>Revenue share</div>
                        {
                          product.map(p => {
                            return <div>{(analytics[id]['product'] && analytics[id]['product'][normalizeShopifyID(p.product.id)] && analytics[id]['product'][normalizeShopifyID(p.product.id)]['share']) || '0%'}</div>
                          })
                        }
                        {
                          removedIds && removedIds.map(i => {
                            return <div style={{color:'grey'}}>{analytics[id]['product'][i]['share'] || '0%'}</div>
                          })
                        }
                      </div>
                  }
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>
                { analytics && analytics[id] && formatCurrency(analytics[id].revenue,locale,currency) || formatCurrency(0,locale,currency)}

                {
                  id === productAnalytics && analytics[id] &&
                    <div className='MultiProductAnalytics'>
                      <div className='MultiProductAnalytics__column-title'>Revenue</div>
                      {
                        product.map(p => {
                          return <div>{(analytics[id]['product'] && analytics[id]['product'][normalizeShopifyID(p.product.id)] && formatCurrency(analytics[id]['product'][normalizeShopifyID(p.product.id)]['totalUpsoldRevenue'],locale,currency)) || formatCurrency(0,locale,currency)}</div>
                        })
                      }
                      {
                        removedIds && removedIds.map(i => {
                          return <div style={{color:'grey'}}>{formatCurrency(analytics[id]['product'][i]['totalUpsoldRevenue'],locale,currency) || formatCurrency(0,locale,currency)}</div>
                        })
                      }
                    </div>
                }
              </IndexTable.Cell>
              <IndexTable.Cell>
                { analytics && analytics[id] && analytics[id].conversion || '0%'}

                {
                  id === productAnalytics && analytics[id] &&
                    <div className='MultiProductAnalytics'>
                      <div className='MultiProductAnalytics__column-title'>Conversions</div>
                      {
                        product.map(p => {
                          return <div>{(analytics[id]['product'] && analytics[id]['product'][normalizeShopifyID(p.product.id)] && analytics[id]['product'][normalizeShopifyID(p.product.id)]['totalCount']) || '0'}</div>
                        })
                      }
                      {
                        removedIds && removedIds.map(i => {
                          return <div style={{color:'grey'}}>{analytics[id]['product'][i]['totalCount'] || '0'}</div>
                        })
                      }
                    </div>
                }
              </IndexTable.Cell>
              <IndexTable.Cell>
                <OfferIdContainer>
                  <Text  variant="bodyMd" as="span" tone="subdued">
                    {id}
                  </Text>
                  <Button  onClick={(e) => copyIdToClipboard(e, id)} variant="plain">
                    <img src={ClipboardMinor} alt={'ClipboardMinor'} />
                  </Button>
                </OfferIdContainer>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <ActionsWrapper>
                  <PopoverAction
                    id={id}
                    actions={offerActions(id, offerType, cartType, checkoutType, analytics, rule, rules, token, shop, host, firebase, setToastActive)}
                    popoverActive={rulePopoverActive}
                    togglePopoverActive={togglePopoverActive}
                  />
                </ActionsWrapper>
              </IndexTable.Cell>
            </IndexTable.Row>
          );
        }
      );
    }
    return null;
  };

  return (
    <ListMainWrapper>
      <div>
        <CustomIndexFilters
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          sortOption={sortOption}
          setSortOption={setSortOption}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          searchValue={searchValue}
          handleTextFieldChange={handleTextFieldChange}
          selectedOffersFilters={selectedOffersFilters}
          setSelectedOffersFilters={setSelectedOffersFilters}
        />
        <IndexTable
          resourceName={{singular: 'offer', plural: 'offers'}}
          itemCount={rules?.length || 0}
          selectedItemsCount={
            allResourcesSelected ? 'All' : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          headings={[
            {title: 'EmptyRow', hidden: true},
            {title: 'Image', hidden: true},
            {title: 'Priority'},
            {title: 'Name'},
            {title: 'Status'},
            {title: 'Type'},
            {title: 'Impressions'},
            {title: 'Revenue'},
            {title: 'Conversion'},
            {title: 'ID'},
            {title: 'Actions', hidden: true},
          ]}
          // selectable
        >
          {renderRows()}
        </IndexTable>
      </div>
      <Frame>
        {toastMarkup}
      </Frame>
    </ListMainWrapper>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case 'accountStatus':
        return value.map((val) => `Customer ${val}`).join(', ');
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}

export default List;